* {
  scroll-behavior: smooth;
}

.chat::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.chat::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the scrollbar track */
}

.chat::-webkit-scrollbar-thumb {
  background-color: rgba(
    100,
    100,
    100,
    0.3
  ); /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
}

.chat::-webkit-scrollbar-thumb:hover {
  background-color: rgba(
    100,
    100,
    100,
    0.5
  ); /* Set the color of the scrollbar thumb on hover */
}

.chat::-webkit-scrollbar-thumb:active {
  background-color: rgba(
    100,
    100,
    100,
    0.7
  ); /* Set the color of the scrollbar thumb when active */
}
